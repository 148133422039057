import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = (props) => (
  <Container fluid className="d-flex">
    <Row className="justify-content-center align-self-center w-100 text-center pt-1 pb-3">
      {!!props.message && <p>{props.message}</p> }<Spinner style={{color: '#009966'}} />
    </Row>
  </Container>
);

export default Loader;
