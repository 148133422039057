import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
  extranet as extyRoutes,
} from "./index";

import ExtranetLayout from "../layouts/Extranet";
import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import {connect} from "react-redux";

const childRoutes = (Layout, routes, entity_type) => {
  // equiv: function ({ children, path, component: Component }, index) {  }
  return routes.map(({children, path, component: Component, roles}, index) => {
    return children ? (
      // Route item with children
      children.map(({path, component: Component, roles}, index) => {
        // console.log('Path & roles', path, roles);
        if (!roles || roles.includes(entity_type)) {
          return (
            <Route
              onChange={()=>console.log('fuck me')}
              key={index}
              path={path}
              exact
              render={props => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          );
        }
      })
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  });
}

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            {/* fn: childRoutes(Layout, routes) */}
            {childRoutes(ExtranetLayout, extyRoutes, this.props.user.currentUser.entity_type)}
            {childRoutes(LandingLayout, landingRoutes)}
            {childRoutes(DashboardLayout, dashboardRoutes)}
            {childRoutes(AuthLayout, pageRoutes)}
            <Redirect exact from="/" to="/extranet/dashboard" />
            <Redirect exact from="/extranet/" to="/extranet/dashboard" />
            { this.props.user.entity_id == 0 ? <Redirect to="/extranet/dashboard" /> : <Redirect to="/extranet/auth/signin" /> }
            <Route
              render={() => (
                <AuthLayout>
                  <Page404 />
                </AuthLayout>
              )}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user,
})

export default connect(
  mapStateToProps,
)(Routes);
