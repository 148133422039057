// import axios from 'axios'
import {extranetApi} from "../../services/Api";

export function loginUser(userObj) {
  // console.log('loginUser: returning LOGIN_USER object');
  return {
    type: 'LOGIN_USER',
    payload: userObj,
  }
}

export function logOutUser(userObj) {
  // console.log('authActions::logOutUser: returning LOGOUT_USER object');
  return {
    type: 'LOGOUT_USER',
    payload: userObj,
  }
}

export function fetchProfile(userObj) {
  // console.log('ActioN: fetchProfile', userObj);
  return {
    type: 'FETCH_PROFILE',
    payload: userObj,
  }
}

export function fetchDashboard(dashboard) {
  // console.log('ActioN: fetchDashboard', dashboard);
  return {
    type: 'FETCH_DASHBOARD',
    payload: dashboard,
  }
}

export const userLogOut = (dispatch) => {

  extranetApi.get('logout')
    .then((response) => {

      // console.log(response);
      if (response.status === 200) {

        if (response.data.success === true) {

          // console.log("API :: Logged out", response.data);
          dispatch(response.data.data);
          window.location.href = '/extranet/auth/signin';

        } else {

          console.log(response.data.message);
          // alert('Error: ' + response.data.message);

        }

      }
      else {
        // Unknown error
        console.log("Unknown server error");
        // alert("Unknown server error");
      }


    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });


}

export const getUserProfile = (dispatch) => {

  extranetApi.get('profile')
    .then((response) => {

      // console.log('FUCKING PROFILE RESPONSE: ', response);
      if (response.status === 200) {

        if (response.data.success === true) {

          // console.log("Got Profile", response.data);

          dispatch(response.data.data);

        } else {

          console.log(response.data.message);
          // alert('Error: ' + response.data.message);

        }

      }
      else {
        // Unknown error
        console.log("Unknown server error");
        // alert("Unknown server error");
      }


    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

}

export const userLoginFetch = (user, dispatch) => {

  const ERROR_INVALID_LOGIN = 1021;

  let payload = {
    "login[username]": user.email,
    // "login[username]": user.username,
    "login[password]": user.password
  }
  // console.log('AuthActions::userLoginFetch', payload);

  const querystring = require('querystring');
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  extranetApi.post('login', querystring.stringify(payload), { headers: headers })
    .then(function (response) {

      // console.log('userLoginFetch::Api post response', response);

      if (response.status === 200) {

        if (response.data.success === true) {

          // console.log("Login successful");
          // alert("Login successful");

          // let symfony = response.data.data.symfony;
          // console.log('Symfony', symfony);
          // console.log('Full response', response.data.data);

          // console.log('DISPATCH', dispatch);
          dispatch(response.data.data);

          return true;

        } else {

          console.log('Login failed: ', response.data.message);
          // alert(response.data.message);

        }

      } else if (response.response.status == 400 && response.response.data.error == ERROR_INVALID_LOGIN) {

        console.log('Login validation failed');

        // Validation failure
        dispatch(response.response.data);

      }
      else {

        // Unknown error
        console.log("Login failed: Unknown server error");
        // alert("Unknown server error");
      }

    })
    .catch(function (error) {
      console.log('Login failed: serious unknown error', error);
    });

}

export const getProfileFetch = () => {
  // return dispatch => {
  return () => {

    console.log('Getting ping');
    extranetApi.get('ping')
      .then(function (response) {
        console.log(response);
      });

/*
    return fetch("http://localhost:3000/api/v1/profile", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
          // An error will occur if the token is invalid.
          // If this happens, you may want to remove the invalid token.
          localStorage.removeItem("token")
        } else {
          dispatch(loginUser(data.user))
        }
      })
*/

  }
}