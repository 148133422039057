import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

// import cabfare from "../../assets/img/cabfare/cabfare-logo-green.png"
import avatar from "../../assets/img/cabfare/cabfare-logo.svg";
import {extranetApi} from "../../services/Api";
import ExtLoader from "../../components/ExtLoader";
import {OutlineAlert} from "./components/ExtAlerts";

const querystring = require('querystring');

/*
const ExtLogout = () => (
  <h1>Logout</h1>
);
*/

/*
const ExtLogin = () => (
  <React.Fragment>
    <div className="text-center mt-4">
      {/!*<h2>Welcome back, Chris</h2>*!/}
      <p className="lead">Sign in to your account to continue</p>
    </div>

    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
            <img
              src={cabfare}
              alt="CabFare"
              className="img-fluid"
              // width="132"
              // height="100"
              style={{maxHeight: '100px'}}
            />
          </div>
          <Form>
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="Enter your password"
              />
              <small>
                <Link to="/extranet/auth/forgot-password">Forgot password?</Link>
              </small>
            </FormGroup>
{/!*
            <div>
              <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              />
            </div>
*!/}
            <div className="text-center mt-3">
              <Link to="/dashboard/default">
                <Button color="primary" size="lg">
                  Sign in
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
);
*/

class ExtPasswordReset extends React.Component
{
  constructor(props)
  {
    super(props);
    this.props = props;

    // console.log('Props', props);

    this.state = {
      isLoading: true,
      isSubmitting: false,
      minLength: 6,
      maxLength: 99,
      password: '',
      password2: '',
      resetKey: props.match.params.resetKey,
      entityType: '',
      entityName: '',
      contactEmail: '',
      isValid: false,
      error: false,
      success: false,
    };

  }

  componentDidMount() {

    extranetApi.get('checkResetKey', {
      params: {
        resetKey: this.state.resetKey,
      }
    })
      .then(
        (response) => {
          // debugger;
          if (response.status === 200) {
            if (response.data.success === true) {
              this.setState({
                ...this.state,
                isValid: true,
                isLoading: false,
                entityType: response.data.data.entityType,
                entityName: response.data.data.entityName,
                contactEmail: response.data.data.contactEmail,
              });
            } else if (response.data.data.error) {
              console.log('Error:', response.data.data.error);
              this.setState({
                ...this.state,
                isValid: false,
                isLoading: false,
                error: response.data.data.error,
              });
            }
          }
        }
      )
      .catch((error) => {
        console.log('!ERROR: ', error);
        // @todo: Handle this error properly
      });

  }

  handleChange = (event) => {
    if (!this.state.success) {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value,
      }, () => {
        console.log(this.state);
      });
    }
  }

  canSavePassword = () => {
    return (
      !this.state.isSubmitting &&
      !this.state.success &&
      !!this.state.password &&
      !!this.state.password2 &&
      (this.state.password === this.state.password2)
    );
  }

  handleSubmit = (event) => {

    event.preventDefault();
    console.log('Sending forgot password...');

    this.setState({
      ...this.state,
      error: false,
      isSubmitting: true,
    }, () => {

      extranetApi.post('passwordReset', querystring.stringify({
        password1: this.state.password,
        password2: this.state.password2,
        resetKey: this.state.resetKey,
      }))
        .then(
          (response) => {
            // debugger;
            if (response.status === 200) {
              if (response.data.success === true) {
                this.setState({
                  ...this.state,
                  success: true,
                  isSubmitting: false,
                });
              } else if (response.data.data.error) {
                console.log('Error:', response.data.data.error, response.data.data.message);
                this.setState({
                  ...this.state,
                  isSubmitting: false,
                  error: response.data.data.error,
                });
              }
            } else {
              console.log('ERROR: response != 200');
              console.log(response);
              this.setState({
                ...this.state,
                isSubmitting: false,
              });
            }
          }
        )
        .catch((error) => {
          console.log('!ERROR: ', error);
          // @todo: Handle this error properly
        });

    });

  }

  render() {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          { this.state.contactEmail && (<p>Resetting password for {this.state.contactEmail}</p>) }
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={avatar}
                  alt="Cabfare"
                  className="img-fluid"
                  width="300"
                  style={{marginBottom: '2rem'}}
                />
              </div>
              { !this.state.isValid ? (
                <React.Fragment>
                  { this.state.isLoading && (
                    <div>
                      <p>Checking reset key...</p>
                      <ExtLoader />
                    </div>
                  ) }
                  { !this.state.isLoading && (
                    <div>
                      <OutlineAlert
                        color="danger"
                      >
                        {this.state.error}
                      </OutlineAlert>
                      <p>To try again, go to the <Link to="/extranet/forgot-password">password reset page</Link>.</p>
                      <p>Or else please contact us <a href="http://www.cabfare.com.au/contact-us">here</a></p>
                    </div>
                  ) }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  { this.state.error && (
                    <div>
                      <OutlineAlert
                        color="danger"
                      >
                        {this.state.error}
                      </OutlineAlert>
                      <p>Please try again or contact us <a href="http://www.cabfare.com.au/contact-us">here</a></p>
                    </div>
                  ) }
                  { this.state.success && (
                    <div>
                      <OutlineAlert
                        color="success"
                      >
                        Password reset was successful.
                      </OutlineAlert>
                      <p><Link to="/extranet/auth/signin">Please return to the home page to log in.</Link></p>
                    </div>
                  ) }
                   <Form onSubmit={this.handleSubmit} className="form-login">
                    <FormGroup>
                      <Label for="password">Please enter a new password. The password must be at least {this.state.minLength} characters.</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        minLength={this.state.minLength}
                        maxLength={this.state.maxLength}
                        value={this.state.password}
                        onChange={e => {this.handleChange(e)}}
                        autoComplete="false"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password2">Confirm</Label>
                      <Input
                        type="password"
                        name="password2"
                        id="password2"
                        minLength={this.state.minLength}
                        maxLength={this.state.maxLength}
                        placeholder="Confirm password"
                        value={this.state.password2}
                        onChange={e => {this.handleChange(e)}}
                        autocomplete="current-password"
                      />
                    </FormGroup>
                    <div className="text-center mt-3">
                      <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={!this.canSavePassword()}
                      >
                        Reset password
                      </Button>
                    </div>
                  </Form>
                </React.Fragment>
              ) }
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } // render

} // ExtPasswordReset

class ExtConfirmEmail extends React.Component
{
  constructor(props)
  {
    super(props);
    this.props = props;

    console.log('Props', props);

    this.state = {
      isLoading: true,
      isSubmitting: false,
      verifyToken: props.match.params.verifyToken,
      email: '',
      isValid: false,
      error: false,
      success: false,
    };

  }

  componentDidMount() {

    extranetApi.get('confirmEmailToken', {
      params: {
        verifyToken: this.state.verifyToken,
      }
    })
      .then(
        (response) => {
          // debugger;
          if (response.status === 200) {
            if (response.data.success === true) {
              this.setState({
                ...this.state,
                isValid: true,
                isLoading: false,
                success: true,
                email: response.data.data.email,
              }, ()=>{
                console.log('new fucking state', this.state);
              });
            } else if (response.data.data.error) {
              console.log('Error:', response.data.data.error);
              this.setState({
                ...this.state,
                isValid: false,
                isLoading: false,
                error: response.data.data.error,
              });
            }
          }
        }
      )
      .catch((error) => {
        console.log('!ERROR: ', error);
        // @todo: Handle this error properly
      });

  }

  render() {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Confirm email</h1>
          {/*{ this.state.contactEmail && (<p>Resetting password for {this.state.contactEmail}</p>) }*/}
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={avatar}
                  alt="Cabfare"
                  className="img-fluid"
                  width="300"
                  style={{marginBottom: '2rem'}}
                />
              </div>
              { !this.state.isValid ? (
                <React.Fragment>
                  { this.state.isLoading && (
                    <div>
                      <p>Validating request...</p>
                      <ExtLoader />
                    </div>
                  ) }
                  { !this.state.isLoading && (
                    <div>
                      <OutlineAlert
                        color="danger"
                      >
                        {this.state.error}
                      </OutlineAlert>
                      <p>To try again, go to your <Link to="/extranet/settings">Settings</Link> section within the Extranet.</p>
                      <p>Or else please contact us <a href="http://www.cabfare.com.au/contact-us">here</a></p>
                    </div>
                  ) }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  { this.state.success && (
                    <div>
                      <OutlineAlert
                        color="success"
                      >
                        Success. Your email address has been updated to {this.state.email}.
                      </OutlineAlert>
                      <p><Link to="/extranet/auth/signin">Please return to the home page to log in.</Link></p>
                    </div>
                  ) }
                </React.Fragment>
              ) }
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } // render

} // ExtConfirmEmail

class ExtForgotPassword extends React.Component
{
  constructor(props)
  {
    super(props);
    this.props = props;

    this.state = {
      email: '',
      error: false,
      success: false,
    };

  }

  handleChange = (event) => {
    if (!this.state.success) {
      this.setState({
        ...this.state,
        email: event.target.value,
      });
    }
  }

  handleSubmit = (event) => {

    event.preventDefault();
    console.log('Sending forgot password...');

    this.setState({
      ...this.state,
      error: false,
    }, ()=>{

      extranetApi.get('forgotpassword', {
        params: {
          email: this.state.email,
        }
      })
        .then(
          (response) => {
            // debugger;
            if (response.status === 200) {
              if (response.data.success === true) {
                this.setState({
                  ...this.state,
                  success: true,
                });
              } else if (response.data.data.error) {
                console.log('Error:', response.data.data.error, response.data.data.message);
                this.setState({
                  ...this.state,
                  error: true,
                  errorMessage: response.data.data.error,
                });
              }
            }
          }
        )
        .catch((error) => {
          console.log('!ERROR: ', error);
          // @todo: Handle this error properly
        });

    });

  }

  render() {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={avatar}
                  alt="Cabfare"
                  className="img-fluid"
                  width="300"
                  style={{marginBottom: '2rem'}}
                />
              </div>
              { this.state.error && (
                <div>
                  <OutlineAlert
                    color="success"
                  >
                    {this.state.errorMessage}
                  </OutlineAlert>
                  <p>Please try again or contact us <a href="http://www.cabfare.com.au/contact-us">here</a></p>
                </div>
              ) }
              { this.state.success && (
                <OutlineAlert
                  color="success"
                  // message={'Please check your email, confirmation instructions have been sent to ' + this.state.account.username + '.'}
                >
                  <strong>Please check your email.</strong> Instructions for resetting your password have been sent to {this.state.email}.
                </OutlineAlert>
              ) }
              <Form onSubmit={this.handleSubmit} className="form-login">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    size="lg"
                    type="submit"
                  >
                    Reset password
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  } // render

} // ExtForgotPassword

// export {ExtLogin, ExtLogout, ExtResetPassword};
export {ExtForgotPassword, ExtPasswordReset, ExtConfirmEmail};
