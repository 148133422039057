import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import {Redirect} from "react-router"

import {
  // Row,
  // Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // ListGroup,
  // ListGroupItem,
} from "reactstrap";

import {
  // Settings,
  User,
} from "react-feather";


import avatar0 from "../assets/img/avatars/avatar-0.png";
import avatar1 from "../assets/img/avatars/avatar.jpg";
import avatar2 from "../assets/img/avatars/avatar-2.jpg";
import avatar3 from "../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../assets/img/avatars/avatar-5.jpg";
// import {fetchProfile, getUserProfile, logOutUser, userLoginFetch, userLogOut} from "../redux/actions/authActions";
import {fetchProfile, getUserProfile, logOutUser, userLogOut} from "../redux/actions/authActions";
import {Link, NavLink} from "react-router-dom";

/*
const ExtNavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const ExtNavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);
*/

class ExtNavbarComponent extends React.Component {

  constructor(props) {

    super(props);

    const timeoutMinutes = 15;
    this.avatars = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5];

    // Set the timeout to start now
    var user = this.props.user;
    user.timeoutMinutes = timeoutMinutes;
    user.timeout = timeoutMinutes * 60;

    this.state = {
      user: user,
      userName: user.currentUser.login,
      userType: user.currentUser.user_type,
      userAvatar: this.avatars[user.currentUser.avatar],
      environment: user.currentUser.environment ? user.currentUser.environment : 'prod',
      timeout: user.timeout,
    };

    this.doLogOut = this.doLogOut.bind(this);

  }

  componentDidMount() {

    this.props.fetchUserData();
    if (this.state.environment != 'dev') {
      this.startTimer();
    }

  }

  doLogOut() {
    // console.log('Doing log out');
    document.body.classList.add('logout');
    this.props.logOut();
  }

  // Timeout
  countdown() {
    let timeout = this.props.user.timeout;
    timeout -= 1;
    if (timeout <= 0) {
      // Got to logout
      this.props.logOut();
      // done in authActions: window.location.href = '/extranet/auth/signin';
    }
    this.props.user.timeout = timeout;
    this.setState({
      timeout: timeout,
      timeoutString: this.formatTimeout(timeout),
    });
  }
  formatTimeout(time) {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    seconds = seconds.toString();
    if (seconds.length == 1) {
      seconds = "0" + seconds;
    }
    return minutes + ':' + seconds;
  }
  stopTimer() {
    clearInterval(this.timer);
  }
  startTimer() {
    if (this.state.environment != 'dev') {
      this.timer = setInterval(() => this.countdown(), 1000);
    }
  }
  componentWillUnmount() {
    this.stopTimer();
  }
  // End timeout

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props != prevProps) {
      var user = this.props.user;
      // console.log('ExtNavbar :: componentDidUpdate', this.state.userName, user.currentUser.login);
      var newState = {
        ...this.state,
        user: user,
        userName: user.currentUser.login,
        userAvatar: this.avatars[user.currentUser.avatar],
        environment: user.currentUser.environment,
      };
      // console.log('ExtNavbar :: newState', newState);
      this.setState(newState);
    }
    // this.user = this.props.user;
    // this.userName = this.user.currentUser.login;
    // this.userAvatar = this.avatars[this.user.currentUser.avatar];
  }

  render() {

    let showSettings = this.state.userType != 'driver' && this.state.userType != 'factorer';

    return ( !this.state.user.currentUser.id ? ( <Redirect to='/extranet/auth/signin' /> ) : (
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={this.props.toggleSidebar}
        >
          <i className="hamburger align-self-center"/>
        </span>
        <span className="entity-name d-sm-inline-block pl-2">
          <h3>{this.state.user.currentUser.entity_name}</h3>
        </span>
        { this.state.environment && this.state.environment != 'prod' && (
          <span className="dev-stage d-sm-inline-block pl-2 mr-3 mr-sm-0">
            <h5 className="text-warning">(<span className="d-none d-sm-inline-block">Dev/Staging: </span>{this.state.environment})</h5>
          </span>
        )}

        <Collapse navbar>

          <Nav className="ml-auto text-right text-sm-left mr-1 mr-sm-0" navbar>

            <UncontrolledDropdown nav inNavbar>
              <span className="nav-timeout d-block d-sm-inline-block mr-1 mr-sm-3">
                Timeout in: {this.state.timeoutString}
              </span>
{/*
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle"/>
                </DropdownToggle>
              </span>
*/}
              { showSettings ? (
                <span className="user-name d-inline-block mr-sm-3">
                  <DropdownToggle nav caret>
                    <img
                      src={this.state.userAvatar}
                      className="avatar img-fluid rounded-circle"
                      alt={this.state.userName}
                    />
                    <span className="text-dark d-none d-sm-inline-block pl-1">{this.state.userName}</span>
                  </DropdownToggle>
                </span>
              ) : (
                <span className="user-name d-none d-sm-inline-block">
                  <img
                    src={this.state.userAvatar}
                    className="avatar img-fluid rounded-circle"
                    alt={this.state.userName}
                  />
                  <span className="text-dark pl-1">{this.state.userName}</span>
                </span>
              )}
              <span className="nav-logout d-inline-block ml-2 ml-sm-3" style={{wordWrap: 'none'}}>
                {/*<a href="javascript:;" onClick={ this.doLogOut }>Log out</a>*/}
                <NavLink to="#" onClick={ this.doLogOut }>Log out</NavLink>
              </span>

              { showSettings && (
              <DropdownMenu right>
                <DropdownItem tag={Link} to="/extranet/settings">
                  <User size={18} className="align-middle mr-2"/>
                  Settings
                </DropdownItem>
{/*
                <DropdownItem divider/>
                <DropdownItem onClick={this.props.logOut}>Log out</DropdownItem>
*/}
              </DropdownMenu>
              )}

            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    ));

  } // render
}

const mapStateToProps = store => ({
  user: store.user,
});

const fetchUserData = (dispatch) => {
  getUserProfile((userObj) => { // userObj is the value passed back into this anonymous function
    return dispatch(fetchProfile(userObj));
  });
}

const fetchLogOut = (dispatch) => {
  userLogOut((userObj) => {
    return dispatch(logOutUser(userObj));
  });
}

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
  fetchUserData: () => fetchUserData(dispatch),
  logOut: () => fetchLogOut(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtNavbarComponent);
