import React from "react";
import { Container, Row, Col } from "reactstrap";
import {connect} from "react-redux";

const Footer = (props) => {

  let className = '';
  if (!!props.login) {
    className = ' login';
  } else if (props.sidebar.isOpen) {
    className = ' sidebar-toggled';
  }

  return (
  <footer className={"footer" + className}>
    <Container fluid>
      <Row >
        <Col className="footer-items text-left col-sm-8">
          <ul className="list-inline">
            <li className="list-inline-item col-3 col-sm-auto">
              <span >
                <a href="//www.cabfare.com.au/" target="_blank" rel="noopener">
                  Home
                </a>
              </span>
            </li>
            <li className="list-inline-item col-3 col-sm-auto">
              <span>
                <a href="//www.cabfare.com.au/contact" target="_blank" rel="noopener">
                  Contact Us
                </a>
              </span>
            </li>
            <li className="list-inline-item col-3 col-sm-auto">
              <span>
                <a href="//www.cabfare.com.au/privacy" target="_blank" rel="noopener">
                  Privacy
                </a>
              </span>
            </li>
            <li className="list-inline-item col-3 col-sm-auto">
              <span>
                <a href="//www.cabfare.com.au/terms" target="_blank">
                  Terms
                </a>
              </span>
            </li>
          </ul>
        </Col>
        <Col className="footer-copyright col-12 col-sm-4 text-center text-sm-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <span>
              CabFare
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)};

export default connect(
  store => ({
    sidebar: store.sidebar,
  })
)(Footer);
