import Cookies from 'universal-cookie';
import lscache from 'lscache';

const cookies = new Cookies();

let currentUser = cookies.get('current_user');
// console.log('Loaded cookie of current_user: ', currentUser);

// console.log('AVATAR: ', Math.floor(Math.random() * Math.floor(5)));

const initialState = {
  currentUser: currentUser ? currentUser : {
    'user_type': 'driver',
    'regulator_only': false,
    'login': '',
    'id': 0,
    'entity_type': '',
    'entity_id': 0,
    'entity_name': '',
    'full_name': '',
    'environment': '',
    'symfony': '',
    'avatar': Math.floor(Math.random() * Math.floor(5))
  },
  news: {},
  dashboard: {
    chart: {
      labels: [],
      datasets: []
    }
  }
}

export default function authReducer(state = initialState, action) {

  switch (action.type) {

    case 'UPDATE_EXT_SETTINGS_ALIAS':
    {
      // console.log('UPDATE_EXT_SETTINGS_ALIAS', action.payload);
      let newState = {
        ...state,
        currentUser: {
          ...state.currentUser,
          terminal_label: action.payload,
        }
      };
      cookies.set('current_user', newState.currentUser, {path: '/'});
      return newState;
    }

    case 'UPDATE_EXT_SETTINGS_USER':
    {
      const user = action.payload;
      const fullName = user.firstName + ' ' + user.lastName;
      let newState = {
        ...state,
        currentUser: {
          ...state.currentUser,
          full_name: fullName,
          first_name: user.firstName,
          last_name: user.lastName,
          login: user.username,
        }
      };
      cookies.set('current_user', newState.currentUser, {path: '/'});
      return newState;
    }

    case 'LOGIN_USER':
    {
      // console.log('authReducer :: LOGIN_USER');
      let newState = {
        ...state,
        currentUser: {
          // ...state.currentUser,
          ...action.payload
        }
      };
      if (!newState.currentUser.error) {
        // console.log('Storing Symfony cookie: ', newState.currentUser.symfony);
        cookies.set('current_user', newState.currentUser, {path: '/'});
        // cookies.set('symfony', newState.currentUser.symfony, {path: '/'});
        // Clear localStorage for new session
        lscache.flush();
      }
      // console.log('LOGIN_USER new state', newState);
      return newState;
    }

    case 'LOGOUT_USER':
    {
      let newState = {
        ...state,
        currentUser: {
          'user_type': 'driver',
          'regulator_only': false,
          'login': 'Logged Out',
          'id': 0,
          'entity_type': '',
          'entity_id': 0,
          'entity_name': '',
          'full_name': '',
          'symfony': '',
          'environment': '',
          'avatar': Math.floor(Math.random() * Math.floor(5))
        }
      };
      cookies.remove('current_user', {path: '/'});
      cookies.remove('symfony', {path: '/'});
      // Clear out local storage too
      lscache.flush();
      // console.log('LOGOUT_USER new state', newState);
      return newState;
    }

    case 'FETCH_PROFILE':
    {
      // console.log('!authReducer! fetch profile', action);
      let newState = {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      };
      cookies.set('current_user', newState.currentUser, {path: '/'});
      // console.log('FETCH_PROFILE new state', newState);
      return newState;
    }

    case 'FETCH_DASHBOARD':
    {
      // console.log('!authReducer!', action);
      let newState = {
        ...state,
        dashboard: action.payload,
      };
      // console.log('FETCH_DASHBOARD new state', newState);
      return newState;
    }

    default:
      return state;
  }
}
