import React from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import {userLoginFetch, loginUser} from '../../redux/actions/authActions';
import {Redirect} from "react-router"

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import avatar from "../../assets/img/cabfare/cabfare-logo.svg";
import {OutlineAlert} from "./components/ExtAlerts";

class ExtSignIn extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      user: props.user
    };
  }

  validateForm = () => this.state.email.length > 0 && this.state.password.length > 0;

  handleChangeUsername = event => {
    this.setState({
      email: event.target.value
    });
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {

    event.preventDefault();
    // console.log('Starting sign in...');
    return this.props.fetchUserLogin(this.state);

  }

  render() {
    return ( this.props.user.currentUser.id ? ( <Redirect to='/extranet/dashboard' /> ) : (
      <React.Fragment>
        <div className="text-center mt-4">
          <p className="lead">Sign in to your account to continue</p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={avatar}
                  alt="Cabfare"
                  className="img-fluid"
                  width="300"
                  style={{marginBottom: '2rem'}}
                />
              </div>
              { this.props.user.currentUser.error && (
                <>
                  <OutlineAlert
                    color="danger"
                  >
                    {this.props.user.currentUser.message}
                  </OutlineAlert>
                  {/*<div className="alert alert-danger" style={{padding: '1rem'}}></div>*/}
                </>
              ) }
              <Form onSubmit={this.handleSubmit} className="form-login">
                <FormGroup>
                  <Label for="username">Email or Driver ID</Label>
                  <Input
                    bsSize="large"
                    autoFocus
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter your Email or Driver ID"
                    value={this.state.email}
                    onChange={this.handleChangeUsername}
                    autoComplete="username"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password or PIN</Label>
                  <Input
                    bsSize="large"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your Password or PIN"
                    value={this.state.password}
                    onChange={this.handleChange}
                    autoComplete="current-password"
                  />
                  <div className="mt-3">
                    <Link to="/extranet/forgot-password">Forgot password?</Link>
                  </div>
                </FormGroup>
{/*
                <div>
                  <CustomInput
                    type="checkbox"
                    id="rememberMe"
                    label="Remember me next time"
                    defaultChecked
                  />
                </div>
*/}
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    size="lg"
                    disabled={!this.validateForm()}
                    type="submit"
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    ));
  } // render

} // class SignIn

const fetchUserLogin = (user, dispatch) => {
  // console.log('Fetching User Login');
  userLoginFetch( user,(userObj) => {  // passing state and anonymous fn to userLoginFetch
    // console.log('Dispatching login details to Redux Store', userObj);
    return dispatch(loginUser(userObj));
  });
}

const mapStateToProps = store => ({
  user: store.user,
  // sidebar: store.sidebar,
  // layout: store.layout
})

const mapDispatchToProps = dispatch => ({ // implicit return
  fetchUserLogin: (user) => fetchUserLogin(user, dispatch),
})

// export default SignIn;
export default connect(mapStateToProps, mapDispatchToProps)(ExtSignIn);
