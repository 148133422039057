import React from "react";

import Wrapper from "../components/Wrapper";
import ExtSidebar from "../components/ExtSidebar";
import Main from "../components/Main";
import ExtNavbar from "../components/ExtNavbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
// import Settings from "../components/Settings";

const Extranet = ({ children }) => (
  <React.Fragment>
    <Wrapper>
      <ExtSidebar />
      <Main>
        <ExtNavbar />
        <Content>{children}</Content>
        <Footer />
      </Main>
    </Wrapper>
    {/*<Settings />*/}
  </React.Fragment>
);

export default Extranet;
