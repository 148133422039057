import React from "react";
import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import reduxStore from "../redux/store/index";
import {logOutUser} from "../redux/actions/authActions";
import lscache from 'lscache';

const extranetApi = axios.create(ApiConfig);
const TTL_MINUTES = 15;

const {dispatch} = reduxStore; // direct access to redux store.
extranetApi.interceptors.response.use(response => {
  let user = reduxStore.getState().user;
  user.timeout = user.timeoutMinutes * 60;
  return response;
}, error => {
  if (error.response && error.response.status && error.response.status === 401) {
    // Unauthorised - Just get straight to the login page
    dispatch(logOutUser({}));
    window.location.href = '/extranet/auth/signin';
  }
  return error;
});

export {extranetApi, TTL_MINUTES};

// HOC for fetching data
export const withApi = (url, dispatchFn) => (Component) =>
  class WithApi extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        data: null,
        isLoading: false,
        error: null,
        url: url,
      };
      this.Api = extranetApi;
    }

    getData() {

      this.setState({
        ...this.state,
        isLoading: true
      }, function() {

        this.Api.get(this.state.url)
          .then(
            (response) => {
              // console.log('API :: ', response);
              if (response.status === 200) {
                if (response.data.success === true) {
                  this.setState({
                    ...this.state,
                    data: response.data.data,
                    isLoading: false,
                  }, function() {
                    // console.log('Loaded data = ', this.state.data);
                    lscache.set(this.state.url, this.state.data, TTL_MINUTES);
                  });
                  if (dispatchFn) {
                    this.props[dispatchFn](response.data.data);
                  }
                  // console.log(this.state);
                }
              }
            }
          )
          .catch((error) => {
            console.log(error);
            this.setState({
              ...this.state,
              error: error,
              isLoading: false,
            })
          });

      });

    }

    componentDidMount() {

      let localData = lscache.get(this.state.url);
      // console.log('localData', localData);
      if (typeof localData === 'string') {
        localData = JSON.parse(localData);
      }
      // console.log('localData', localData);
      // console.log('withAPI: localData', this.state.url, localData);
      if (localData) {
        this.setState({
          ...this.state,
          data: localData,
          isLoading: false,
        });
      } else {
        this.getData();
      }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      // console.log('componentDidUpdate', prevProps, prevState, snapshot);
    }

    doRefresh() {
      // console.log('Refreshing that shit');
    }

    render() {
      return <Component { ...this.props } { ...this.state } doRefresh={this.doRefresh} />;
    }

  } // WithApi class
